// 1. Import statements should always be at the top
import React from "react";
import commonbannerimg from "../assets/commonbannerimg.jpeg";
import { useForm } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BiPhoneCall } from "react-icons/bi";
import { MdOutlineMail } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import { SlSocialLinkedin } from "react-icons/sl";
import { IoLogoInstagram } from "react-icons/io";
import { LuFacebook } from "react-icons/lu";
import { RxTwitterLogo } from "react-icons/rx";
import MapPage from "./MapPage";
import { useState } from "react";

const ContactUs = () => {
  const { register, handleSubmit, formState: { errors }, reset } = useForm();
  const [showForm, setShowForm] = useState(true);

  const onSubmit = (data) => {
    const { name, email, phone, message } = data;

    // WhatsApp Number (अपना नंबर डालें)
    const phoneNumber = "917316981246";

    // WhatsApp Message Format
    const whatsappMessage = `Hello! I'm ${name}. I am interested in your services. Here are my details:\n
    Name: ${name}\n
    Email: ${email}\n
    Phone: ${phone}\n
    Message: ${message}`;

    // WhatsApp URL Generate
    const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(whatsappMessage)}`;

    // WhatsApp Open in New Tab
    window.open(whatsappUrl, "_blank");

    // Reset Form
    reset();
    setShowForm(false); // Hide the form after submission
  };


  return (
    <>
      <div className="bg-gray-50 text-gray-800 mt-[64px]">
        {/* Banner Section */}
        <div
          className="bg-cover bg-center text-white  lg:h-56 md:h-56 p-8 flex flex-col justify-center items-center relative"
          style={{ backgroundImage: `url(${commonbannerimg})` }}
        >
          <h1 className="text-4xl font-bold relative z-10">Contact Us</h1>
          <p className="text-sm mt-2 relative z-10">Home / Contact Us</p>
        </div>

        {/* Get In Touch Section */}
        <div className="text-center py-8 bg-white">
          <p className="font-semibold text-sm md:text-base tracking-wide text-orange-600">
            \ Get In Touch \
          </p>
          <h2 className="text-2xl md:text-4xl font-bold mt-2">
            Hey! Let’s Talk
          </h2>
        </div>

        {/* Content Section */}
        <div className="container mx-auto  flex flex-col md:flex-row justify-between items-center bg-white px-3 md:px-14 py-8">
          {/* Form Section */}
          <div className="w-full md:w-2/3">
      {showForm ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <input
            type="text"
            placeholder="Name"
            className="w-full text-black text-base bg-gray-100 mb-4 px-4 py-2 rounded-lg"
            {...register("name", { required: "Name is required" })}
          />
          {errors.name && <p className="text-red-500 text-sm">{errors.name.message}</p>}

          <input
            type="email"
            placeholder="Email"
            className="w-full text-black text-base bg-gray-100 mb-4 px-4 py-2 rounded-lg"
            {...register("email", {
              required: "Email is required",
              pattern: {
                value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
                message: "Please enter a valid email address",
              },
            })}
          />
          {errors.email && <p className="text-red-500 text-sm">{errors.email.message}</p>}

          <input
            type="tel"
            placeholder="Phone"
            className="w-full text-black text-base bg-gray-100 mb-4 px-4 py-2 rounded-lg"
            {...register("phone", {
              required: "Phone number is required",
              pattern: {
                value: /^[0-9]{10}$/,
                message: "Please enter a valid 10-digit phone number",
              },
            })}
          />
          {errors.phone && <p className="text-red-500 text-sm">{errors.phone.message}</p>}

          <textarea
            placeholder="Your Message"
            className="w-full text-black text-base bg-gray-100 mb-4 px-4 py-2 resize-none rounded-lg"
            rows="4"
            {...register("message", { required: "Message is required" })}
          />
          {errors.message && <p className="text-red-500 text-sm">{errors.message.message}</p>}

          <button
            type="submit"
            className="w-full md:w-32 bg-custom-orengelight text-white font-bold py-4 rounded-lg hover:bg-orange-600 transition duration-300"
          >
            Send Now
          </button>
        </form>
      ) : (
        <p className="text-green-500 text-lg font-semibold">Your message has been sent via WhatsApp!</p>
      )}

      <ToastContainer />
    </div>

          {/* Contact Info Section */}
          <div className="w-full md:w-1/2 flex flex-col bg-white text-gray-800 p-6 sm:p-10 lg:p-16 space-y-8">
            {/* Call Section */}
            <div className="flex items-center space-x-4">
              <div className="flex items-center justify-center w-12 h-12 bg-gray-50 rounded-md shadow-lg">
                <BiPhoneCall className="text-2xl text-orange-600" />
              </div>
              <div>
                <h3 className="text-lg font-semibold text-orange-600">
                  Call Anytime
                </h3>
                <p className="text-sm">+91 6376925384</p>
                <p className="text-sm">+91 9352381775</p>
              </div>
            </div>

            {/* Email Section */}
            <div className="flex items-center space-x-4">
              <div className="flex items-center justify-center w-12 h-12 bg-gray-50 rounded-md shadow-lg">
                <MdOutlineMail className="text-2xl text-orange-600" />
              </div>
              <div>
                <h3 className="text-lg font-semibold text-orange-600">
                  Send Email
                </h3>
                <p className="text-sm">support@takmonboost.com</p>
                <p className="text-sm">takmonboost@gmail.com</p>
              </div>
            </div>

            {/* Address Section */}
            <div className="flex items-center space-x-4">
              <div className="flex items-center justify-center w-12 h-12 bg-gray-50 rounded-md shadow-lg">
                <FaLocationDot className="text-2xl text-orange-600" />
              </div>
              <div>
                <h3 className="text-lg font-semibold text-orange-600">
                  Visit Us
                </h3>
                <p className="text-sm">
                  D78, Raj Laxmi residency, Gandhi Path W, Vaishali Nagar,
                  Jaipur, Rajasthan
                </p>
              </div>
            </div>

            {/* Social Media Section */}
            <div className="w-full flex flex-col space-y-4">
              <h3 className="text-xl font-bold text-gray-800">Follow us</h3>
              <div className="flex space-x-4">
  <a
    href="https://www.linkedin.com/company/99989554/admin/dashboard/"
    target="_blank"
    rel="noopener noreferrer"
    className="flex items-center justify-center w-12 h-12 bg-orange-500 text-white hover:bg-orange-600 rounded-md"
  >
    <SlSocialLinkedin className="text-2xl" />
  </a>
  <a
    href="https://www.instagram.com/takmonboost/"
    target="_blank"
    rel="noopener noreferrer"
    className="flex items-center justify-center w-12 h-12 bg-orange-500 text-white hover:bg-orange-600 rounded-md"
  >
    <IoLogoInstagram className="text-2xl" />
  </a>
  <a
    href="https://www.facebook.com/profile.php?viewas=100000686899395&id=61566405033702"
    target="_blank"
    rel="noopener noreferrer"
    className="flex items-center justify-center w-12 h-12 bg-orange-500 text-white hover:bg-orange-600 rounded-md"
  >
    <LuFacebook className="text-2xl" />
  </a>
  <a
    href="https://x.com/takmonboost"
    target="_blank"
    rel="noopener noreferrer"
    className="flex items-center justify-center w-12 h-12 bg-orange-500 text-white hover:bg-orange-600 rounded-md"
  >
    <RxTwitterLogo className="text-2xl" />
  </a>
</div>

            </div>
          </div>
        </div>

        <div className="w-full">
          <MapPage />
        </div>
      </div>
    </>
  );
};

export default ContactUs;
