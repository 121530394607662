import React from "react";
import commonbannerimg from "../assets/commonbannerimg.jpeg";
import image11 from "../assets/About us/abouts12.jpeg";
import aboutblack from "../assets/About us/aboutblack.jpeg";
import { useNavigate } from "react-router-dom";
import TeamSection from "./common/TeamSlider";
import Button from "./common/Button";

const AboutUs = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    console.log("Button clicked!");
    navigate("/contact");
  };
  return (
    <>
      <div className="bg-gray-50 text-gray-800 mt-[64px]">
        {/* Header Section */}
        <div
          className="bg-cover bg-center text-white h-[300px] md:h-56 p-8 flex flex-col justify-center items-center relative"
          style={{
            backgroundImage: `url(${commonbannerimg})`, // Corrected this line
          }}
        >
          <h1 className="text-4xl font-bold relative z-10">About Us</h1>
          <p className="text-sm mt-2 relative z-10">Home / About Us</p>
        </div>

        {/* About Us Section */}
        <div className="flex flex-wrap items-center justify-center lg:justify-between gap-y-8 gap-x-0 px-6 py-12 bg-white lg:px-28 1024x1366:px-8">
          {/* Left Side: Images */}
          <div className="flex flex-col w-full lg:w-5/12 space-y-4 items-center lg:items-start">
            <div className="flex">
              <img
                src={image11}
                alt="Scan QR"
                className="w-full lg:w-[400px] xl:w-[500px] rounded-md shadow-lg"
              />
            </div>
          </div>

          {/* Right Side: Text Content */}
          <div className="w-full lg:w-5/12 text-center lg:text-left mt-6 lg:mt-0 mb-7">
            <h2 className="text-orange-600 font-semibold text-xl">
              {" "}
              \ About Us \{" "}
            </h2>
            <h1 className="text-3xl lg:text-4xl font-bold text-gray-800 mt-3">
              Company About Us
            </h1>
            <p className="text-gray-600 mt-3 leading-relaxed">
            At its core, the Takmon Boost is a highly result-oriented and outcome-based company that specializes in digital marketing service. We excel in SEO-related services and boost the rankings and quality traffic while increasing conversions, all of these through innovative techniques and expert viewpoints.
            </p>

            {/* Stats Section */}
            <div className=" mt-11 flex sm:flex-row justify-evenly items-center  gap-y-4 lg:gap-y-0 gap-x-6 shadow-lg rounded-lg p-4 lg:p-0">
              <div className="flex flex-col items-center text-center">
                <span className="text-2xl font-bold text-gray-800">99+</span>
                <span className="text-gray-500 text-sm">Happy Clients</span>
              </div>
              <div className="flex flex-col items-center text-center">
                <div className="text-2xl font-bold text-gray-800">4 Years</div>
                <span className="text-gray-500 text-sm">Year In Business</span>
              </div>
              <div className="flex flex-col items-center text-center">
                <span className="text-2xl font-bold text-gray-800">25+</span>
                <span className="text-gray-500 text-sm">Projects Done</span>
              </div>
            </div>
          </div>
        </div>

        {/* get a touch */}
        <div className="sm:p-0 md:p-10 lg:p-40 rounded-lg">
          <div
            className="bg-cover bg-center text-white h-[300px] md:h-56 p-8 flex flex-col justify-center items-center relative  rounded-lg"
            style={{
              backgroundImage: `url(${aboutblack})`, // Corrected this line
            }}
          >
            <p className="text-white text-base lg:text-2xl mb-8">
              “Some of the History of Our Company is that we are Catching up
              through Video”
            </p>
            <Button
              type="submit"
              text="Get In Touch"
              className={"bg-custom-orenge p-2 px-5 rounded-md shadow-md"}
              onClick={handleClick}
            />
            {/* <img
          src={aboutblack}
          alt="Team Discussion"
          className="rounded-lg w-full h-auto"
        /> */}
          </div>
        </div>

        <div>
          {/* <TeamSection /> */}
        </div>
      </div>
    </>
  );
};

export default AboutUs;
