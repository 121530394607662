import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import "./App.css";
import AppRoutes from "./routes/AppRoutes";
import image from "../src/assets/reCAPTCHA.png";

function App() {
  const [showForm, setShowForm] = useState(false);
  const { register, handleSubmit, formState: { errors } , reset } = useForm();

  // This function will be triggered when the form is submitted
  const onSubmit = (data) => {
    // Extract form data
    const { name, email, phone, message } = data;

    // WhatsApp message setup
    const phoneNumber = "917316981246"; // Replace with your phone number
    const whatsappMessage = `Hello! I'm ${name}. I am interested in your services. Here are my details:\n
    name: ${name}\n
    Email: ${email}\n
    Phone: ${phone}\n
    Message: ${message}`;
    
    // WhatsApp URL
    const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(whatsappMessage)}`;
    
    // Open WhatsApp in a new tab
    window.open(whatsappUrl, "_blank");

    console.log("Form submitted:", data);  // This will show the form data in the console

    // Reset form fields
    reset();
    // Hide the form after submission
    setShowForm(false); // Hide the form after submission
  };

  // Auto open form every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setShowForm(true);
    }, 20000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      {/* Contact Form (Always on Top) */}
      {showForm && (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
          <div className="w-80 md:w-96 bg-[#EC7712] p-6 rounded-lg shadow-lg text-white">
            {/* Header */}
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold">Let’s Grow Together!</h2>
              <button onClick={() => setShowForm(false)} className="text-white text-lg font-bold">
                &times;
              </button>
            </div>

            {/* Form */}
            <form onSubmit={handleSubmit(onSubmit)} className="space-y-3">
              <div>
                <input type="text" placeholder="Name" {...register("name", { required: "Name is required" })} className="w-full p-3 rounded-md text-gray-900" />
                {errors.name && <p className="text-red-200 text-sm">{errors.name.message}</p>}
              </div>

              <div>
                <input type="email" placeholder="Email" {...register("email", { required: "Email is required" })} className="w-full p-3 rounded-md text-gray-900" />
                {errors.email && <p className="text-red-200 text-sm">{errors.email.message}</p>}
              </div>

              <div>
                <input type="tel" placeholder="Phone" {...register("phone", { required: "Phone number is required" })} className="w-full p-3 rounded-md text-gray-900" />
                {errors.phone && <p className="text-red-200 text-sm">{errors.phone.message}</p>}
              </div>

              <div>
                <textarea placeholder="Your Message" {...register("message", { required: "Message is required" })} className="w-full p-3 h-24 rounded-md text-gray-900" />
                {errors.message && <p className="text-red-200 text-sm">{errors.message.message}</p>}
              </div>

              {/* reCAPTCHA Placeholder */}
              <div className="w-full bg-white p-4 flex items-center justify-between gap-4 rounded-md border border-gray-300 ">
                <input type="checkbox" className="w-5 h-5" />
                <span className="text-gray-500">I’m not a robot</span>
                <img src={image} alt="reCAPTCHA" className="h-6 ml-auto" />
              </div>

              {/* Submit Button */}
              <div className="flex justify-end">
                <button type="submit" className="w-1/2 bg-white text-orange-600 font-semibold py-3 rounded-md hover:bg-gray-100 transition">
                  Submit Now
                </button>
              </div>

            </form>
          </div>
        </div>
      )}

      {/* Home Component and Routes */}
      <AppRoutes />
    </div>
  );
}

export default App;
