import React from 'react'
import BannerSection from '../SeoServices/BannerSection'
import severs from "../../assets/Services/severs.png";
import OurPartner from '../OurPartner';

import { useNavigate } from 'react-router-dom';
import YourSteps from '../YourSteps';
const SeoServices = () => {
    const navigate = useNavigate();

    const steps = [
        { number: '01', title: 'Local Business SEO', description: "IThese days, local businesses desperately need to cut through this ever-increasing list of online contenders. Being either a small player or an old-established one, a strong presence online serves to get .... ", route: '/services/seo/local-business' },
        { number: '02', title: 'Outsource SEO ', description: 'Takmon Lift is the best Website design enhancement organization in Jaipur, offering master re-appropriating Web optimization best seo company in Jaipur  administrations to help your web-based presence. ....', route: '/services/seo/outsource' },
        { number: '03', title: 'VSmall Business SEO  ', description: "Present times is a digital world, wherein small businesses find themselves being challenged by various competitions; staying ahead of competition often falls under the heads of some key tools... ", route: '/services/seo/small-business' },
        { number: '04', title: 'On-Page SEO ', description: 'As digital marketing is one of the increasingly competitive fields of the day, mastering SEO for achieving success online. One such significant aspect of SEO has been On-page SEO or The Best SEO Agency in Noida process of optimizing your website .. ', route: '/services/seo/onpage' },
        { number: '05', title: 'Off-Page SEO ', description: "Presently, in the online world, a good online presence becomes significantly important for any successful business. While on-page SEO plays a crucial role to enhance your website's ranking, the off-page ", route: '/services/seo/offpage' },
        { number: '06', title: 'Technical SEO', description: 'With the rise in the digital world, businesses gradually started realizing the importance of an online presence. Be it a brand at local level or an international player, having a good website visibility strategy is of prime importance', route: '/services/seo/technical' },
    ];

    const handleStepClick = (step) => {
        if (step.route) {
            navigate(step.route);
        } else {
            console.log(`Clicked step: ${step.title}`);
        }
    };
  return (
    <div>
    <BannerSection
        title="SEO Service "
        description="Takmon Boost is an SEO Company in India that offers advanced solutions for your online visibility. This team of professionals has special expertise in keyword optimization and link building, along with creating quality content marketing. They offer customized SEO strategies with success to boost your rankings and attract high-quality traffic."

        
        mainImage={severs}
        overlayImage={severs}
      />
     <YourSteps steps={steps} onStepClick={handleStepClick} />
      <OurPartner/>
    </div>
  )
}

export default SeoServices
